
export default {
  props: ["communityID"],
  data: () => ({
    listingData: [],
    isLoading: true,
    page: 1,
    keyword: "",
    intervalID: null,
    isModalOpened: false,
    isDeleteModalOpened: false,
    selectedContentId: "",
    selectedListingId: "",
    loading: {
      sold: false,
    },
  }),
  async mounted() {
    this.isLoading = true;
    this.addPage();
    this.intervalID = setInterval(this.addPage, 3000);

    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
    this.$nuxt.$on("onClickSell", this.onClickSellHandler);
    this.$nuxt.$on("onClickDelete", this.onClickDelete);
  },
  methods: {
    async addPage() {
      const params = {
        limit: 10,
        p: this.page,
        keyword: this.keyword,
      };
      const res = await this.$listingService.getListingbyCommunity(
        this.communityID,
        params
      );
      // const res = await this.$getListingContentbyCommunity(
      //   this.communityID,
      //   10,
      //   this.page
      // );

      this.listingData = [...this.listingData, ...res];

      this.page = this.page + 1;
      if (res.length < 10) {
        clearInterval(this.intervalID);
      }
    },
    async onSearch(keyword) {
      this.isLoading = true;
      clearInterval(this.intervalID);
      this.keyword = keyword;
      this.page = 1;
      this.listingData = [];
      this.addPage();
      this.intervalID = setInterval(this.addPage, 3000);
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    onClickSellHandler(communityContentId, listingId) {
      this.isModalOpened = true;
      this.selectedContentId = communityContentId;
      this.selectedListingId = listingId;
    },
    async sellListingHandler() {
      this.loading.sold = true;
      try {
        const res = await this.$axios.post(
          `${process.env.BASE_API}communities/questions/${this.selectedContentId}`,
          {
            id_community: this.$route.params.id,
            is_sold: 1,
          }
        );
        this.listingData = this.listingData.map((listing) => {
          if (listing.id === this.selectedListingId) {
            return {
              ...listing,
              marketplace_item: { ...listing.marketplace_item, is_sold: 1 },
            };
          } else return { ...listing };
        });
        this.isModalOpened = false;
        this.$message({
          message: "Berhasil menjual barang",
          type: "success",
        });
      } catch (error) {
        this.$message({
          message: "Gagal update listing",
          type: "error",
        });
        throw error;
      } finally {
        this.loading.sold = false;
      }
    },
    onClickDelete(communityContentId) {
      this.selectedContentId = communityContentId;
      this.isDeleteModalOpened = true;
    },
    deleteListing() {
      this.$editQuestion(this.selectedContentId, { is_active: 0 })
        .then((response) => {
          this.$notify.success({
            message: "Listing berhasil dihapus",
            offset: 100,
            duration: 3500,
            onClose: () => {
              window.location.reload();
            },
          });
        })
        .catch((err) => {
          this.$notify.error({
            message: "Listing gagal dihapus",
            offset: 100,
            duration: 3500,
          });
        })
        .finally(() => {
          this.isDeleteModalOpened = false;
        });
    },
  },
};
